import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { StoriesFransuite, linkResolver } from '@pwc-new-ventures/ui';
import Layout from '../layouts';

const postPrefix = 'stories';

export default function() {
  const { contentfulStoriesPage, allContentfulPost } = useStaticQuery(graphql`
    {
      contentfulStoriesPage(slug: { eq: "stories" }) {
        metaTitle
        metaDescription
        image {
          ...ImageSharpMedium
        }
        heroCtaLink {
          slug
          internal {
            type
          }
        }
        heroCtaText
        heroCtaCopy
        heroDescription {
          json
        }
        heroHeadline
        heroImage {
          ...ImageSharpLarge
        }
        categories {
          id
          name
          expandCta
        }
        spacerNavigator {
          headline
          ctaText
          ctaLink {
            ...NavigatorCtaLink
          }
        }
      }
      allContentfulPost(sort: { order: [ASC, DESC], fields: [pinned, createdAt] }) {
        group(field: category___id) {
          fieldValue
          totalCount
          nodes {
            pinned
            createdAt
            headline
            ctaText
            slug
            redirectUrl
            internal {
              type
            }
            image {
              ...ImageSharpStoryCard
            }
          }
        }
      }
    }
  `);

  const doc = {
    meta: {
      title: contentfulStoriesPage.metaTitle,
      description: contentfulStoriesPage.metaDescription,
      image:
        contentfulStoriesPage.image &&
        contentfulStoriesPage.image.localFile.childImageSharp.fluid.src,
    },
    hero: {
      headline: contentfulStoriesPage.heroHeadline,
      body: contentfulStoriesPage.heroDescription,
      buttonHeadline: contentfulStoriesPage.heroCtaCopy,
      buttonText: contentfulStoriesPage.heroCtaText,
      buttonUrl:
        contentfulStoriesPage.heroCtaLink &&
        linkResolver({
          type: contentfulStoriesPage.heroCtaLink.internal.type,
          slug: contentfulStoriesPage.heroCtaLink.slug,
          postPrefix,
        }),
      image: contentfulStoriesPage.heroImage,
    },
    categories: contentfulStoriesPage.categories.map(({ id, name, expandCta }) => ({
      headline: contentfulStoriesPage.categories.length > 1 && name,
      buttonText: expandCta,
      cards: allContentfulPost.group
        .filter(({ fieldValue }) => fieldValue === id)
        .pop()
        .nodes.map((card) => ({
          headline: card.headline,
          linkText: card.ctaText,
          linkUrl: linkResolver({
            type: card.internal.type,
            slug: card.redirectUrl || card.slug,
            postPrefix,
          }),
          image: card.image,
        })),
    })),
    cta: {
      headline: contentfulStoriesPage.spacerNavigator[0].headline,
      buttonText: contentfulStoriesPage.spacerNavigator[0].ctaText,
      buttonType:
        contentfulStoriesPage.spacerNavigator[0].ctaLink &&
        contentfulStoriesPage.spacerNavigator[0].ctaLink[0].internal.type,
      buttonUrl:
        contentfulStoriesPage.spacerNavigator[0].ctaLink &&
        linkResolver({
          type: contentfulStoriesPage.spacerNavigator[0].ctaLink[0].internal.type,
          slug:
            contentfulStoriesPage.spacerNavigator[0].ctaLink[0].redirectUrl ||
            contentfulStoriesPage.spacerNavigator[0].ctaLink[0].slug,
          postPrefix,
        }),
      primary: true,
    },
  };

  return (
    <Layout title={doc.meta.title} description={doc.meta.description} image={doc.meta.image}>
      <StoriesFransuite doc={doc} Link={Link} />
    </Layout>
  );
}
